<template>
  <v-app id="inspire">
    <v-app-bar id="scroll-target" app color="white" flat>
      <v-app-bar-nav-icon
        class="d-flex d-sm-none"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <v-avatar size="64"
        ><img src="../assets/128.png" alt="Sree Dattha" />
      </v-avatar>
      <v-tabs v-model="tab" class="ml-n1 tab-width d-none d-sm-flex" right>
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab
          v-for="(link, i) in links"
          :key="i"
          @click="$vuetify.goTo(link.tab)"
        >
          {{ link.menu }}
        </v-tab>
      </v-tabs>
      <v-dialog v-model="dialog" persistent max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="btn-quote btn-primary-c"
            rounded
            dark
            v-bind="attrs"
            v-on="on"
          >
            Get A Quote
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <h4 class="section-title">LET'S GET STARTED</h4>
          </v-card-title>
          <v-card-text>
            <v-container class="get-a-quote">
              <v-row>
                <v-col cols="12">
                  <v-text-field label="First Name*" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Last Name*"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Mobile Number*"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email*"
                    type="email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-chip-group active-class="primary" column>
                    <v-chip v-for="tag in tags" :key="tag">
                      {{ tag }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false"> CANCEL </v-btn>
            <v-btn
              class="btn-primary-c theme--dark v-btn"
              @click="dialog = false"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <!-- Add a navigation bar -->
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-for="(item, index) in links" :key="index">
            <v-list-item-title @click="tab = index">{{
              item.menu
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- Navigation bar ends -->
    <v-btn
      v-show="fab"
      v-scroll="onScroll"
      fab
      dark
      fixed
      bottom
      right
      class="btn-primary-c"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <v-main>
      <section id="home">
        <v-parallax :src="imageLink.backgroundImage" height="600" dark>
          <v-layout align-center justify-end class="white--text">
            <v-card class="home-banner-card" height="130" dark>
              <v-card-title class="text-h5 card-title mt-5">
                Connecting people with their lifestyle
              </v-card-title>
              <v-card-subtitle
                >Unlesh your creativity without limitations</v-card-subtitle
              >
            </v-card>
          </v-layout>
        </v-parallax>
      </section>
      <!-- :style="cssProps" -->
      <section id="about" class="section-lg bg-white bg-img-custom">
        <v-container>
          <v-layout wrap class="my-5">
            <v-col
              cols="12"
              md="7"
              justify-center
              align-center
              class="service-image"
            >
              <v-img :src="imageLink.whyChoose" height="650"></v-img>
            </v-col>
            <v-col cols="12" md="5">
              <div class="box-custom-2">
                <h2 class="section-title">Why Choose Us</h2>
                <p class="mt-2">
                  We connect people with their life style and create a space
                  without limitations.
                </p>
                <v-row class="service-list">
                  <v-col>
                    <v-list-item
                      v-for="(item, i) in items"
                      :key="i"
                      cols="12"
                      class="unit-space index-list"
                    >
                      <v-list-item-icon class="icon-align">
                        <v-img :src="item.image"></v-img>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="font-styles">{{
                          item.title
                        }}</v-list-item-title>
                        <v-list-item-subtitle class="content-align">
                          {{ item.content }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-layout>
        </v-container>
      </section>
      <section>
        <v-row no-gutters>
          <v-col v-for="feature in features" :key="feature" cols="12" sm="4">
            <v-card class="bg-color">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="mb-1 feature-content">
                    <v-list-item-icon class="feature-icon">
                      <v-img :src="feature.image" class="mr-4"></v-img>
                      <span class="mt-4">{{
                        feature.title
                      }}</span></v-list-item-icon
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </section>
      <section id="services">
        <h2 class="section-title">Our Services</h2>
        <v-container>
          <v-layout wrap class="my-5">
            <v-card
              v-for="(item, i) in services"
              :key="i"
              class="mx-auto my-5 service-section"
            >
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-img :src="item.image" height="300px" />
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-card-title> {{ item.title }} </v-card-title>
                  <v-card-text class="services-content">
                    {{ item.content }}
                    <div>
                      <v-btn
                        class="btn-primary-c mt-5"
                        rounded
                        align="right"
                        :to="item.route"
                      >
                        Read More...
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
        </v-container>
      </section>
      <section id="portfolio">
        <h2 class="section-title">WHAT PEOPLE SAY</h2>
        <v-container>
          <v-layout wrap class="my-5">
            <v-carousel>
              <template v-for="(item, index) in reviews">
                <v-carousel-item
                  v-if="(index + 1) % columns === 1 || columns === 1"
                  :key="index"
                >
                  <v-row class="flex-nowrap" style="height: 100%">
                    <template v-for="(n, i) in columns">
                      <template v-if="+index + i < reviews.length">
                        <v-col :key="i">
                          <v-sheet
                            v-if="+index + i < reviews.length"
                            height="100%"
                          >
                            <v-row
                              class="fill-height people-say"
                              align="center"
                              justify="center"
                              :set="(user = +index + i)"
                            >
                              <v-card class="mx-auto" max-width="300">
                                <v-card-title>
                                  <v-icon large left>
                                    mdi-account-circle
                                  </v-icon>
                                  <span class="text-h6 font-weight-light">{{
                                    reviews[user].name
                                  }}</span>
                                </v-card-title>

                                <v-card-text class="review-content">
                                  {{ reviews[user].content }}
                                </v-card-text>
                              </v-card>
                            </v-row>
                          </v-sheet>
                        </v-col>
                      </template>
                    </template>
                  </v-row>
                </v-carousel-item>
              </template>
            </v-carousel>
          </v-layout>
        </v-container>
      </section>
      <section id="contact">
        <h2 class="section-title">CONTACT US</h2>
        <v-container>
          <v-layout wrap class="my-5">
            <v-col cols="12" sm="12" md="7">
              <GmapMap
                :center="center"
                :zoom="12"
                style="width: 100%; height: 300px"
              >
                <GmapMarker
                  v-for="(m, index) in markers"
                  :key="index"
                  :position="m.position"
                  @click="center = m.position"
              /></GmapMap>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-card class="mx-auto sr-box-shadow">
                <v-card-text>
                  <ul>
                    <li>
                      <span class="title">P:</span>
                      <span class="desc"
                        >+91-7032057063<br />
                        +91-9676065948</span
                      >
                    </li>
                    <li>
                      <span class="title">E:</span>
                      <span class="desc">sreedatthacncinterio@gmail.com </span>
                    </li>
                    <li>
                      <span class="title">A:</span>
                      <span class="desc">
                        Usha Mullapudi Cardio Hospital Rd, Gajularamaram,
                        Kailash Hills, Mahadevpur Colony, Hyderabad, Telangana
                        500055</span
                      >
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-col>
          </v-layout>
        </v-container>
      </section>
    </v-main>
    <v-footer dark padless class="footer">
      <v-container>
        <v-layout wrap class="my-5">
          <v-col cols="12" sm="12" md="4">
            <v-card class="mx-auto sr-box-shadow">
              <v-card-text class="footer-text">
                <v-card-title class="footer-h4"
                  >SREE DATTHA CNC CRAFTS & INTERIO </v-card-title
                ><v-divider></v-divider>

                <div class="footer-content">
                  Our aim is to be an organization of value, respect and
                  transparency and to continuously innovate, improve and deliver
                  efficient and effective Interior solutions.
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4"> </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-card class="mx-auto sr-box-shadow">
              <v-card-text class="footer-text">
                <v-card-title class="footer-h4">FOLLOW US</v-card-title>
                <v-divider></v-divider>
                <div class="footer-content">
                  <v-btn
                    v-for="(social, i) in socials"
                    :key="i"
                    class="social-icons"
                    :color="social.color"
                    icon
                    :href="social.link"
                    target="_blank"
                  >
                    <v-icon size="24px" class="social-icons-color">
                      {{ social.icon }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-card-text></v-card
            >
          </v-col>
        </v-layout>
      </v-container>
      <v-layout class="footer-bottom">
        <v-row class="d-flex justify-center copy-right my-1">
          <span class="cpy-right">©Copyright</span>
          <strong
            ><b>Sree Dattha CNC Crafts & Interio.</b> All Rights
            Reserved</strong
          >
        </v-row>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
import logo from "../assets/img.jpeg";
import backgroundImage from "../assets/images/home.jpg";
import whyChoose from "../assets/images/why-choose.jpg";
export default {
  metaInfo: {
    title: "Sree Dattha CNC Crafts & Interio",
    titleTemplate: "%s | Unbelievable 2D & 3D CNC works and Interior Solutions",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "author", content: "Sree Dattha CNC Crafts & Interio" },
      {
        name: "description",
        content:
          "Searching for best home interiors in your low budget with ultimate CNC Works? Visit Sree Dattha CNC Crafts & Interio and make your home beautiful",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        property: "og:title",
        content:
          "Affordable Interior Design services, 2D & 3D CNC Jali Works ← Sree Dattha CNC Crafts & Interio ",
      },
      { property: "og:site_name", content: "Sree Dattha CNC Crafts & Interio" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://sreedatthacncinterio.com/" },
      {
        property: "og:image",
        content: "https://www.sreedatthacncinterio.com/sd-image.jpeg",
      },
      {
        property: "og:description",
        content:
          "Searching for best home interiors in your low budget with ultimate CNC Works? Visit Sree Dattha CNC Crafts & Interio and make your home beautiful",
      },

      {
        name: "keywords",
        content:
          "cnc, cnc work, interiors, best interiors, jali designs, 2D & 3D CNC Jali designers in Hyderbad, Home interiors in Hyderabad",
      },
    ],
    link: [
      {
        rel: "canonical",
        href: "https://www.sreedatthacncinterio.com/sd-image.jpeg",
      },
    ],
  },
  data: () => ({
    dialog: false,
    markers: [],
    tags: ["1BHK", "2BHK", "3BHK", "3BHK+"],
    places: [],
    center: { lat: 45.508, lng: -73.587 },
    drawer: false,
    scrollImages: [
      {
        backgroundImage: backgroundImage,
      },
      {
        backgroundImage: `url(${require("@/assets/images/hom.jpeg")})`,
      },
      {
        backgroundImage: `url(${require("@/assets/image-1.jpg")})`,
      },
    ],
    cssProps: {
      backgroundImage: `url(${require("@/assets/images/why-choose.jpg")})`,
    },
    tab: null,
    fab: false,
    model: 0,
    links: [
      {
        menu: "Home",
        tab: "#home",
      },
      {
        menu: "About",
        tab: "#about",
      },
      {
        menu: "Services",
        tab: "#services",
      },
      { menu: "Contact", tab: "#contact" },
    ],
    features: [
      {
        title: "2 Years Warranty",
        image: require("@/assets/images/feature/warranty.png"),
      },
      {
        title: "45 Days Installation",
        image: require("@/assets/images/feature/install.png"),
      },
      {
        title: "50+ Customers",
        image: require("@/assets/images/feature/people.png"),
      },
    ],
    imageLink: {
      logo: logo,
      backgroundImage: backgroundImage,
      whyChoose: whyChoose,
    },
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    socials: [
      {
        icon: "mdi-facebook",
        color: "indigo",
        link: "https://www.facebook.com/profile.php?id=100076363030472",
      },
      {
        icon: "mdi-linkedin",
        color: "cyan darken-1",
        link: "",
      },
      {
        icon: "mdi-instagram",
        color: "red lighten-3",
        link: "https://www.instagram.com/sreedatthacnccraftsinterio/",
      },
    ],
    items: [
      {
        title: "Highly Skilled Team",
        content: "We are highly trained and make a excellent finish to work",
        image: require("@/assets/images/choose/team.png"),
      },
      {
        title: "Integrity & Quality",
        content:
          "We believes in providing quality of work with high customer satisfaction",
        image: require("@/assets/images/choose/quality.png"),
      },
      {
        title: "Passion & Vision",
        content:
          "We designs your home with passion and made your dreams into reality",
        image: require("@/assets/images/choose/target.png"),
      },
      {
        title: "On-Time Delivery",
        content:
          "We delivered the project within timelines and with good quality of work",
        image: require("@/assets/images/choose/delivery.png"),
      },
    ],
    services: [
      {
        image: require("@/assets/images/hall.jpg"),
        title: "Interior Design",
        route: "interior",
        content:
          "Interior design plays a very important role in our life today, making our lifestyle more modern and stylish. We understand your requirements and make your home beautiful.",
      },

      {
        image: require("@/assets/images/cnc.jpg"),
        title: "CNC Works",
        route: "cnc",
        content:
          "We are engaged in rendering highly reliable CNC 2D Jali Cutting, Engraving, 3D Carving and Wall panels Job Work in wood, mdf, wpvc, plywood and acrylic etc. ",
      },
    ],
    colors: [
      "indigo",
      "warning",
      "pink darken-2",
      "red lighten-1",
      "deep-purple accent-4",
    ],
    reviews: [
      {
        name: "Rakesh Raidu",
        content:
          "Had a great experience with you regarding the interior designing and the cnc work.",
      },
      {
        name: "Chandra Shekar",
        content:
          "Have become a regular customer in a very short span of time. Very approachable staff, service and delivery on time. Fully satisfied. It's five star to sree dattha and team.",
      },
      {
        name: "Pavan",
        content:
          "Liked the work in reasonable rates.loved the design and finishing which I used for my new home decor. Responsible staff delivered on time.",
      },
    ],
  }),
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
  },
};
</script>
