import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=48e1c4c7&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VApp,VAppBar,VAppBarNavIcon,VAvatar,VBtn,VCard,VCardActions,VCardSubtitle,VCardText,VCardTitle,VCarousel,VCarouselItem,VChip,VChipGroup,VCol,VContainer,VDialog,VDivider,VFooter,VIcon,VImg,VLayout,VList,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemSubtitle,VListItemTitle,VMain,VNavigationDrawer,VParallax,VRow,VSheet,VSpacer,VTab,VTabs,VTabsSlider,VTextField})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
